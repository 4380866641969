<template>
  <div v-if="loader">
    Loading...
  </div>
  <div v-if="! loader">
    <BackofficeGlobalHeader :global_err="global_err" />
    <div v-if="global_err == ''">
      <h1>Login</h1>
      <form>
        <div>
          Email:<br>
          <input type="text" v-model="email">
        </div>
        <div>
          Password:<br>
          <input type="password" v-model="password">
        </div>
        <div className="submit_cont">
          <input v-on:click="login" type="submit">
        </div>
        <div className="submit_cont">
          <input type="button" value="Forgot password" v-on:click="forgot_password">
        </div>
        <div>{{ errs }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import BackendModel from "../../models/BackendModel"
import BackofficeGlobalHeader from "./BackofficeGlobalHeader"

export default {
  name: 'Login',
  data() {
    return {
      loader: false,
      email : '',
      password: '',
      errs : '',
      global_err : ''
    }
  },
  components: {BackofficeGlobalHeader},
  async mounted () {
    const backendModel = new BackendModel()
    this.loader = true
    let backoffice_session_id = backendModel.getBackofficeSessionId()
    let authRes = await backendModel.backendRequest('/Api/service/backoffice/auth', {backoffice_session_id})
    if (typeof authRes == 'undefined') {
      this.global_err = 'Backend is down?'
    } else if (authRes.data.res == 'ERROR') {
      this.global_err = authRes.data.e
    } else if (authRes.data.res == 'OK') {
      await this.$router.push({ path: '/backoffice/home' })
    }
    this.loader = false
  },
  methods: {
    async login(e) {
      const backendModel = new BackendModel()
      e.preventDefault()
      this.loader = true
      try {
        let res = await backendModel.backendRequest('/Api/service/backoffice/login', {"email" : this.email, "password" : this.password})
        console.log(res)
        if (res.data.res == 'ERROR') {
          this.global_err = res.data.e
        } else if (res.data.res == 'NOK') {
          this.errs = 'Please try again...'
        } else {
          this.errs = ''
          let CookieDate = new Date
          CookieDate.setFullYear(CookieDate.getFullYear() + 10)
          document.cookie = 'backoffice_session_id='+ res.data.session_id +'; expires=' + CookieDate.toUTCString() + ';'
          await this.$router.push({ path: '/backoffice/home' })
        }
        this.loader = false
      } catch (e) {
        console.log(e)
      }
    },
    async forgot_password() {
      await this.$router.push({ path: '/backoffice/forgot_password' })
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>
